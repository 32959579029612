<template>
<CModal
    color="warning"
    title="Product Data Upload"
    :closeOnBackdrop='false'
    :show.sync="showModal"
    size="lg"
    >
    <h5>Upload File Format</h5>
    <p>CSV file with fields separated by semicolon ( <strong>;</strong> ).<br />
    From Excel use <em>Save As</em> with CSV UTF8 as file format.
    </p>
    <h5>Product Fields:</h5>
    <ul>
        <li><strong>SKU</strong> The unique product SKU. This field is <strong>always required</strong></li>
        <li><strong>ASIN</strong> The Amazon ASIN</li>
        <li><strong>EAN</strong> EAN Code</li>
        <li><strong>GTIN</strong> Global Trade Item Number</li>
        <li><strong><em>[ProductIdentifier]</em></strong> The product identifier for the selected channel (e.g. 'ASIN' for Amazon)</li>
        <li><strong>ReviewUrl</strong> The review url. You can use  placeholders as described below. If the field is present but empty, it will be deleted and the review url configured in the Channel Settings is used instead.</li>
        <li><strong>Title</strong> The product title (max. 50 characters)</li>
        <li><strong>Description</strong> The product title (max. 120 characters)</li>
        <li><strong><em>lang:</em>Title</strong> The translation for the product title. E.g. <span class="text-info">de:Title</span> for the German translation</li>
        <li><strong><em>lang:</em>Description</strong> The translation for the product description. E.g. <span class="text-info">de:Description</span> for the German translation</li>
        <li><strong>Reward<em>[1..9]</em></strong> The SKU of the 1st, 2nd ... reward. E.g. <span class="text-info">Reward1</span>, <span class="text-info">Reward2</span>, <span class="text-info">Reward3</span></li>
    </ul>
    <h5>Review URL placeholders:</h5>
    <p class="mb-1">For the review url the following placeholders are possible:</p>
    <ul class="mb-2">
        <li><strong>{channel}</strong> For amazon this is the marketplace domain, e.g. 'amazon.de'</li>
        <li><strong>{asin}</strong></li>
        <li><strong>{order_no}</strong></li>
        <li><strong>{sku}</strong></li>
        <li><strong>{ean}</strong></li>
        <li><strong>{pzn}</strong></li>
        <li><strong>{<em>ProductIdentifier</em>}</strong></li>
    </ul>    
    <p class="mb-1">Example for an Amazon URL that leads to the order details page:</p>
    <em>https://www.{channel}/gp/your-account/order-details/?ie=UTF8&orderID={order-no}</em>
    <template #footer-wrapper><span></span></template>
    </CModal>
</template>
<script>
export default {
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        show() {
            this.showModal = true;
        }
    }
}
</script>