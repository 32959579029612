<template>
<CCard> 
    <CCardHeader class="pt-3">             
        <h4 class="pb-0 mb-0">Flatfile Upload</h4>          
    </CCardHeader>
    <CCardBody>     
        <UploadFieldsModal ref="helpModal" />
        <CRow class="mb-2">
            <CCol class="sm-12">                
                Not sure what to do? <CLink class="strong" @click="showHelp">Field Explanations</CLink>
            </CCol>
        </CRow>
        <vue-dropzone
            v-if="cenEdit"
            ref="dropzone"
            :includeStyling='true'
            :useCustomSlot="true"
            id="dropzone"
            @vdropzone-total-upload-progress="uploadProgress"
            @vdropzone-sending="sending"
            :options="dropzoneOptions"
            @vdropzone-file-added="fileAdded"
            @vdropzone-success="success"
            @vdropzone-error="error"
        >
            <CIcon name="cil-cloud-upload" size="xl" class="mb-1 mr-2" /> <h5>Drop files here to upload</h5>
        </vue-dropzone>
    <CProgress v-if="isRefreshing || isUploading" class="mb-1" animated striped :value="current" :max="100"  />
    <CRow v-if="cenEdit">
        <CCol class="sm-12 mt-2">            
            <div v-if="channels && channels.length > 1" class="float-left mr-3 d-flex align-items-center">
                <label class="mr-2 mt-0 mb-0 strong">Channel:</label>
                <CSelect class="mb-0" 
                    add-input-classes="text-primary strong"
                    :options='channels' 
                    :value.sync="selectedChannel" 
                    placeholder="Select..."
                    :is-valid="isChannelValid"
                />
            </div>            
            <CButton @click="clickUpload" color="primary" :disabled="isRefreshing || isUploading">Upload</CButton>
        </CCol>
    </CRow>
    <CAlert v-if="isError" color="danger" class="mt-3 p-2 mb-0">
        <h6 class="text-danger">An error occurred. Could not upload or parse file</h6>
        <p class="errors pb-0 mb-0">{{ errorMessage }}</p>
    </CAlert>
    </CCardBody>
</CCard>
</template>
<script>
//https://rowanwins.github.io/vue-dropzone/docs/dist/#/events
import vue2Dropzone from 'vue2-dropzone';
import { getUploadUrl } from '@/api'
import { mapGetters, mapActions } from 'vuex';
import { PUSH_ERROR, PUSH_SUCCESS } from '@/store'
import { REFRESH } from '@/store/modules/auth/action-types';
import { actionNames } from '@/store/modules/products/actions';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import UploadFieldsModal from '@/components/modals/UploadFieldsModal';
import { EDIT_PRODUCT, mapPermissions } from '@/permissions';

export default {    
    computed: {
        ...mapPermissions({
            cenEdit: EDIT_PRODUCT
        }),
        ...mapGetters(['merchantId', 'token', 'tokenExpiration']),
        ...mapGetters({
            authStatus: 'status', 
            channels: 'products/channels',
            isLoading: 'products/loading'
        }),
        isRefreshing() {
            return this.authStatus == 'refreshing';
        }
    },
    data() {
        return {
            dropzoneOptions: {
                url: 'abc',
                withCredentials: true,
                addRemoveLinks: true,
                autoProcessQueue: false,
                maxFiles: 1
            },
            fileName: '',
            isUploading: false,
            current: 0,
            isError: false,
            errorMessage: null,
            selectedChannel: null,
            isChannelValid: null
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
        UploadFieldsModal
    },
    methods: {
        ...mapActions({
            refresh: REFRESH,
            loadChannels: actionNames.LOAD_CHANNELS_UPLOAD
        }),
        async clickUpload() {
            if (this.selectedChannel==null && this.channels && this.channels.length > 1) {
                this.isChannelValid = false;
                return;
            }
            
            this.current = 0;            
            if (!this.tokenExpiration || (this.tokenExpiration <= (new Date().getTime() + 1500)))
            {
                try {
                    await this.refresh();
                }
                catch(e){
                    this.$store.commit(PUSH_ERROR, {message: `An error occured. Please login again`});
                    return;
                }
            }
            this.$refs.dropzone.processQueue();
        },
        /* eslint-disable*/
        sending(file, xhr, formData) {                 
            xhr.setRequestHeader('Authorization', `bearer ${this.token}`);
            this.isUploading = true;
        },
        uploadProgress(totaluploadprogress) {
            this.current = totaluploadprogress;
        },
        fileAdded(f) {
            this.isError = false;
            const queued = this.$refs.dropzone.getQueuedFiles();
            if (queued.length > 0) {
                this.$refs.dropzone.removeFile(queued[0]);
            }
            if (f.upload.filename != null && !f.upload.filename.endsWith('.csv')) {
                this.$store.commit(PUSH_ERROR, {message: `Cannot upload file '${f.upload.filename}'. Only CSV files are allowed!`});
                this.$refs.dropzone.removeAllFiles();
            }
        },
        sendingFiles() {},
        success(file) {
            this.isUploading = false;
            this.$refs.dropzone.removeAllFiles();

            this.$store.commit(PUSH_SUCCESS, {message: `File upload successfully completed!`});

            this.selectedChannel = null;
        },
        /* eslint-disable*/
        error(file, message, xhr) {
            this.isError = true;
            this.errorMessage = message ? message.message : "An unknown error occurred.";
            this.$store.commit(PUSH_ERROR, {message: `An error occured. File upload failed.`});
            this.isUploading = false;            
            this.$refs.dropzone.removeAllFiles();
        },
        async getChannels() {
            this.selectedChannel = null;
            await this.loadChannels();    
        },
        init() {
            this.dropzoneOptions.url = getUploadUrl(this.merchantId);
            this.getChannels();
        },
        showHelp() {
            this.$refs.helpModal.show();
        }
    },
    watch: {
        selectedChannel(newValue) {
            const queryParam = newValue ? `?channel=${newValue}` : '';
            this.dropzoneOptions.url = getUploadUrl(this.merchantId) + queryParam;
            this.$refs.dropzone.setOption('url', this.dropzoneOptions.url);

            if (newValue) {
                this.isChannelValid = null;
            }
        } 
    },
    created() {
        this.init();
    }
}
</script>

<style scoped>
p.errors {
    white-space: pre !important;
}
</style>
